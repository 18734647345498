import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/auth/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/auth/register/register.module").then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: "forget-password",
    loadChildren: () =>
      import("./pages/auth/forget-password/forget-password.module").then(
        (m) => m.ForgetPasswordPageModule
      ),
  },
  {
    path: "sidemenu",
    loadChildren: () =>
      import("./pages/dashboard/sidemenu/sidemenu.module").then(
        (m) => m.SidemenuPageModule
      ),
  },
  {
    path: "gst-verification",
    loadChildren: () =>
      import(
        "./pages/dashboard/verification/gst-verification/gst-verification.module"
      ).then((m) => m.GstVerificationPageModule),
  },
  {
    path: "pdf-viewer",
    loadChildren: () =>
      import(
        "./pages/dashboard/verification/pdf-viewer/pdf-viewer.module"
      ).then((m) => m.PdfViewerPageModule),
  },
  {
    path: "location-tabs",
    loadChildren: () =>
      import(
        "./pages/dashboard/location/location-tabs/location-tabs.module"
      ).then((m) => m.LocationTabsPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
