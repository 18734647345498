// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    // prod
    // apiKey: "AIzaSyAkPO9kn7unScylUdY9v-EknOcjlTGQXbU",
    // authDomain: "firstposter-717c9.firebaseapp.com",
    // projectId: "firstposter-717c9",
    // storageBucket: "firstposter-717c9.appspot.com",
    // messagingSenderId: "312545927375",
    // appId: "1:312545927375:web:ab7385c52d5a949e4897ba",
    // measurementId: "G-S4V0KN7R0C",

    // test
    apiKey: "AIzaSyBCNLi-w0mvS1uWasqRcgiT1EoZw-g8mQ0",
    authDomain: "firstposter-d3c1b.firebaseapp.com",
    projectId: "firstposter-d3c1b",
    storageBucket: "firstposter-d3c1b.appspot.com",
    messagingSenderId: "612948770424",
    appId: "1:612948770424:web:c5212ca36d02336c4b778d",
    measurementId: "G-0KXBFJNWYQ",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
